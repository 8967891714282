//Invoices
//
export const FETCH_INVOICE_LIST = '@@dashboard/fetch-invoice-list';
export const FETCH_INVOICE_LIST_SUCCESS = '@@dashboard/fetch-invoice-list-success';
export const FETCH_INVOICE_LIST_FAILURE = '@@dashboard/fetch-invoice-list-failure';

//
export const FETCH_INVOICE_MONTH_LIST = '@@dashboard/fetch-invoice-month-list';
export const FETCH_INVOICE_MONTH_LIST_SUCCESS = '@@dashboard/fetch-invoice-month-list-success';
export const FETCH_INVOICE_MONTH_LIST_FAILURE = '@@dashboard/fetch-invoice-month-list-failure';

//
export const FETCH_INVOICE_PDF_URL = '@@dashboard/fetch-invoice-pdfUrl';
export const FETCH_INVOICE_PDF_URL_SUCCESS = '@@dashboard/fetch-invoice-pdfUrl-success';
export const FETCH_INVOICE_PDF_URL_FAILURE = '@@dashboard/fetch-invoice-pdfUrl-failure';

//
export const UPDATE_DASHBOARD_INVOICE = '@@dashboard/update-dashboard-invoice';
export const UPDATE_DASHBOARD_INVOICE_SUCCESS = '@@dashboard/update-dashboard-invoice-success';
export const UPDATE_DASHBOARD_INVOICE_FAILURE = '@@dashboard/update-dashboard-invoice-failure';

//
export const SEND_EMAIL_FOR_SIGNATURE_INVOICE = '@@dashboard/email-signature-invoice';

//BILLING INFORMATION
//
export const FETCH_BILLING_LIST = '@@dashboard/fetch-billing-list';
export const FETCH_BILLING_LIST_SUCCESS = '@@dashboard/fetch-billing-list-success';
export const FETCH_BILLING_LIST_FAILURE = '@@dashboard/fetch-billing-list-failure';

//
export const FETCH_BILLING_MONTH_LIST = '@@dashboard/fetch-billing-month-list';
export const FETCH_BILLING_MONTH_LIST_SUCCESS = '@@dashboard/fetch-billing-month-list-success';
export const FETCH_BILLING_MONTH_LIST_FAILURE = '@@dashboard/fetch-billing-month-list-failure';

//
export const FETCH_BILLING_PDF_URL = '@@dashboard/fetch-billing-pdfUrl';
export const FETCH_BILLING_PDF_URL_SUCCESS = '@@dashboard/fetch-billing-pdfUrl-success';
export const FETCH_BILLING_PDF_URL_FAILURE = '@@dashboard/fetch-billing-pdfUrl-failure';
//
export const FETCH_BILLING_INITIAL_LIST = '@@dashboard/fetch-billing-initial-list';
export const FETCH_BILLING_INITIAL_LIST_SUCCESS = '@@dashboard/fetch-billing-initial-list-success';
export const FETCH_BILLING_INITIAL_LIST_FAILURE = '@@dashboard/fetch-billing-initial-list-failure';

//
export const UPDATE_DASHBOARD_BILLING = '@@dashboard/update-dashboard-billing';
export const UPDATE_DASHBOARD_BILLING_SUCCESS = '@@dashboard/update-dashboard-billing-success';
export const UPDATE_DASHBOARD_BILLING_FAILURE = '@@dashboard/update-dashboard-billing-failure';

//
export const RESET_DASHBOARD_BILLING = '@@dashboard/reset-dashboard-billing';
export const RESET_DASHBOARD_BILLING_SUCCESS = '@@dashboard/reset-dashboard-billing-success';
export const RESET_DASHBOARD_BILLING_FAILURE = '@@dashboard/reset-dashboard-billing-failure';

//recharge

//
export const FETCH_RECHARGE_LIST = '@@recharge/dashboard-recharge-list';
export const FETCH_RECHARGE_LIST_SUCCESS = '@@recharge/dashboard-recharge-list-success';
export const FETCH_RECHARGE_LIST_FAILURE = '@@recharge/dashboard-recharge-list-failure';

//recharge onchange activity Type
export const FETCH_CHARGE_LIST = '@@recharge/dashboard-recharge-charge-list';
export const FETCH_CHARGE_LIST_SUCCESS = '@@recharge/dashboard-recharge-charge-list-success';
export const FETCH_CHARGE_LIST_FAILURE = '@@recharge/dashboard-recharge-charge-list-failure';

//
export const FETCH_RECHARGE_MONTH_LIST = '@@dashboard/fetch-recharge-month-list';
export const FETCH_RECHARGE_MONTH_LIST_SUCCESS = '@@dashboard/fetch-recharge-month-list-success';
export const FETCH_RECHARGE_MONTH_LIST_FAILURE = '@@dashboard/fetch-recharge-month-list-failure';

//
export const ADD_RECHARGE = '@@recharge/dashboard-add-recharge';
export const ADD_RECHARGE_SUCCESS = '@@recharge/dashboard-add-recharge-success';
export const ADD_RECHARGE_FAILURE = '@@recharge/dashboard-add-recharge-failure';

//
export const GENERATE_RECHARGE_EXCEL = '@@dashboard/generate-excel-recharge';

//
export const UPDATE_RECHARGE_LIST = '@@recharge/dashboard-update-recharge-list';


//
export const DELETE_RECHARGE = '@@recharge/dashboard-delete-recharge';

//Recharge Rates
//
export const FETCH_RATES_LIST = '@@recharge/dashboard-rates-list';
export const FETCH_RATES_LIST_SUCCESS = '@@recharge/dashboard-rates-list-success';
export const FETCH_RATES_LIST_FAILURE = '@@recharge/dashboard-rates-list-failure';

//
export const ADD_RATE = '@@recharge/dashboard-add-rate';
export const ADD_RATE_SUCCESS = '@@recharge/dashboard-add-rate-success';
export const ADD_RATE_FAILURE = '@@recharge/dashboard-add-rate-failure';

//
export const UPDATE_RATES_LIST = '@@recharge/dashboard-update-rates-list';

//
export const UPLOAD_BULK_RATES_LIST = '@@recharge/dashboard-upload-bulk-rates-list';

//
export const DELETE_RATE = '@@recharge/dashboard-delete-rate';