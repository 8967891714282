import get from 'lodash/get';
import {
    FETCH_INVOICE_LIST, FETCH_INVOICE_LIST_SUCCESS, FETCH_INVOICE_LIST_FAILURE, UPDATE_DASHBOARD_INVOICE, FETCH_BILLING_LIST, FETCH_BILLING_LIST_SUCCESS, FETCH_BILLING_LIST_FAILURE, FETCH_BILLING_INITIAL_LIST, FETCH_BILLING_INITIAL_LIST_SUCCESS, FETCH_BILLING_INITIAL_LIST_FAILURE, UPDATE_DASHBOARD_BILLING, FETCH_BILLING_PDF_URL, FETCH_INVOICE_PDF_URL, SEND_EMAIL_FOR_SIGNATURE_INVOICE, FETCH_INVOICE_MONTH_LIST, FETCH_INVOICE_MONTH_LIST_SUCCESS, FETCH_INVOICE_MONTH_LIST_FAILURE, FETCH_BILLING_MONTH_LIST, FETCH_BILLING_MONTH_LIST_SUCCESS, FETCH_BILLING_MONTH_LIST_FAILURE, FETCH_RATES_LIST, FETCH_RATES_LIST_SUCCESS, FETCH_RATES_LIST_FAILURE, ADD_RATE, ADD_RATE_SUCCESS, ADD_RATE_FAILURE, UPDATE_RATES_LIST, UPLOAD_BULK_RATES_LIST, DELETE_RATE, FETCH_RECHARGE_LIST, FETCH_RECHARGE_LIST_SUCCESS, FETCH_RECHARGE_LIST_FAILURE, ADD_RECHARGE, ADD_RECHARGE_SUCCESS, ADD_RECHARGE_FAILURE, UPDATE_RECHARGE_LIST, DELETE_RECHARGE, FETCH_CHARGE_LIST, FETCH_CHARGE_LIST_SUCCESS, FETCH_CHARGE_LIST_FAILURE, FETCH_RECHARGE_MONTH_LIST, FETCH_RECHARGE_MONTH_LIST_SUCCESS, FETCH_RECHARGE_MONTH_LIST_FAILURE, GENERATE_RECHARGE_EXCEL, RESET_DASHBOARD_BILLING
} from './types';

const initialState = {
    invoiceDashBoardList: [],
    invoiceMonthList: [],
    billingDashBoardList: [],
    billingMonthList: [],
    initialBillingDashBoardList: [],
    rechargeList: [],
    rechargeChargeList: [],
    rateList: [],
    rechargeMonthList: [],
    isRechargeListLoading: false,
    isRechargeChargeListLoading: false,
    isRateListLoading: false,
    isInvoicesDataLoading: false,
    isBillingDataLoading: false,
    isInitialBillingDataLoading: false,
    isMonthListLoading: false,
};

const billingDashBoardReducer = (state = initialState, action) => {
    switch (action.type) {
        //invoiceDashBoardList
        case FETCH_INVOICE_LIST: {
            return {
                ...state,
                isInvoicesDataLoading: true
            }
        }

        case FETCH_INVOICE_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                invoiceDashBoardList: resData,
                isInvoicesDataLoading: false
            }
        }

        case FETCH_INVOICE_LIST_FAILURE: {
            return {
                ...state,
                isInvoicesDataLoading: false
            }
        }

        //invoiceMonthList
        case FETCH_INVOICE_MONTH_LIST: {
            return {
                ...state,
                isMonthListLoading: true
            }
        }

        case FETCH_INVOICE_MONTH_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                invoiceMonthList: resData,
                isMonthListLoading: false
            }
        }

        case FETCH_INVOICE_MONTH_LIST_FAILURE: {
            return {
                ...state,
                isMonthListLoading: false
            }
        }

        //billing pdf Url
        case FETCH_INVOICE_PDF_URL: {
            return {
                ...state,
                isInvoicesDataLoading: true
            }
        }

        //billing list
        case FETCH_BILLING_LIST: {
            return {
                ...state,
                isBillingDataLoading: true
            }
        }

        case FETCH_BILLING_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                billingDashBoardList: resData,
                isBillingDataLoading: false
            }
        }

        case FETCH_BILLING_LIST_FAILURE: {
            return {
                ...state,
                isBillingDataLoading: false
            }
        }

        //billing Months list
        case FETCH_BILLING_MONTH_LIST: {
            return {
                ...state,
                isMonthListLoading: true
            }
        }

        case FETCH_BILLING_MONTH_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                billingMonthList: resData,
                isMonthListLoading: false
            }
        }

        case FETCH_BILLING_MONTH_LIST_FAILURE: {
            return {
                ...state,
                isMonthListLoading: false
            }
        }

        //billing pdf Url
        case FETCH_BILLING_PDF_URL: {
            return {
                ...state,
                isBillingDataLoading: true
            }
        }
        //billing initial state
        case FETCH_BILLING_INITIAL_LIST: {
            return {
                ...state,
                isInitialBillingDataLoading: true
            }
        }

        case FETCH_BILLING_INITIAL_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                initialBillingDashBoardList: resData,
                isInitialBillingDataLoading: false
            }
        }

        case FETCH_BILLING_INITIAL_LIST_FAILURE: {
            return {
                ...state,
                isInitialBillingDataLoading: false
            }
        }

        //Recharge
        case FETCH_RECHARGE_LIST: {
            return {
                ...state,
                isRechargeListLoading: true
            }
        }
        case FETCH_RECHARGE_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                rechargeList: resData,
                isRechargeListLoading: false
            }
        }
        case FETCH_RECHARGE_LIST_FAILURE: {
            return {
                ...state,
                isRechargeListLoading: false
            }
        }
        //recharge charge list
        case FETCH_CHARGE_LIST: {
            return {
                ...state,
                isRechargeChargeListLoading: true
            }
        }
        case FETCH_CHARGE_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                rechargeChargeList: resData,
                isRechargeChargeListLoading: false
            }
        }
        case FETCH_CHARGE_LIST_FAILURE: {
            return {
                ...state,
                isRechargeChargeListLoading: false
            }
        }

        //recharge Months and year List
        case FETCH_RECHARGE_MONTH_LIST: {
            return {
                ...state,
                isMonthListLoading: true
            }
        }

        case FETCH_RECHARGE_MONTH_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                rechargeMonthList: resData,
                isMonthListLoading: false
            }
        }

        case FETCH_RECHARGE_MONTH_LIST_FAILURE: {
            return {
                ...state,
                isMonthListLoading: false
            }
        }

        //Recharge
        case ADD_RECHARGE: {
            return {
                ...state,
                isRechargeListLoading: true
            }
        }

        case ADD_RECHARGE_SUCCESS: {
            return {
                ...state,
                isRechargeListLoading: false
            }
        }

        case ADD_RECHARGE_FAILURE: {
            return {
                ...state,
                isRechargeListLoading: false
            }
        }

        //Recharge

        case DELETE_RECHARGE: {
            return {
                ...state,
                isRechargeListLoading: true
            }
        }

        //

        case GENERATE_RECHARGE_EXCEL: {
            return {
                ...state,
                isRechargeListLoading: true
            }
        }

        //

        case UPDATE_RECHARGE_LIST: {
            return {
                ...state,
                isRechargeListLoading: true
            }
        }

        //Rate
        case FETCH_RATES_LIST: {
            return {
                ...state,
                isRateListLoading: true
            }
        }
        case FETCH_RATES_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                rateList: resData,
                isRateListLoading: false
            }
        }
        case FETCH_RATES_LIST_FAILURE: {
            return {
                ...state,
                isRateListLoading: false
            }
        }

        //Rate
        case ADD_RATE: {
            return {
                ...state,
                isRateListLoading: true
            }
        }

        case ADD_RATE_SUCCESS: {
            return {
                ...state,
                isRateListLoading: false
            }
        }

        case ADD_RATE_FAILURE: {
            return {
                ...state,
                isRateListLoading: false
            }
        }

        //

        case DELETE_RATE: {
            return {
                ...state,
                isRateListLoading: true
            }
        }

        //

        case UPDATE_RATES_LIST: {
            return {
                ...state,
                isRateListLoading: true
            }
        }

        //

        case UPLOAD_BULK_RATES_LIST: {
            return {
                ...state,
                isRateListLoading: true
            }
        }

        // sent email for signature Invoices

        case SEND_EMAIL_FOR_SIGNATURE_INVOICE: {
            return {
                ...state,
                isInvoicesDataLoading: true
            }
        }

        // Update Invoices

        case UPDATE_DASHBOARD_INVOICE: {
            return {
                ...state,
                isInvoicesDataLoading: true
            }
        }

        //update Billing Information
        case UPDATE_DASHBOARD_BILLING: {
            return {
                ...state,
                isBillingDataLoading: true
            }
        }

        //reset Billing Information
        case RESET_DASHBOARD_BILLING: {
            return {
                ...state,
                isBillingDataLoading: true
            }
        }

        default: {
            return state;
        }
    }
};

export default billingDashBoardReducer;