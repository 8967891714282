import React, { useState, useEffect, useRef } from "react";
import SignaturePadLibrary from "signature_pad";
import { Button, Input, Typography, Form, Upload, message } from "antd";
import { SaveOutlined, UndoOutlined, RedoOutlined, DeleteOutlined, EditOutlined, FileImageOutlined, CloudUploadOutlined } from "@ant-design/icons";
const { Title, Text } = Typography;
const SignaturePad = ({ OpenSignaturePad, setOpenSignaturePad, tittle, OngenrateSignedPdf }) => {
    const [savedSignature, setSavedSignature] = useState(false);

    const [nameSignature, setNameSignature] = useState('');

    const [currentMode, setCurrentMode] = useState("draw"); // Modes: draw, type, image
    const [imageData, setImageData] = useState(null)
    const [fileName, setFileName] = useState("")
    const canvasRef = useRef(null);
    const signaturePadRef = useRef(null);
    const redoStack = useRef([]);


    //     var optionsParameter = {
    //         width: 300,
    //         height: 300,
    //         paddingX: 100,
    //         paddingY: 100,
    //         canvasTargetDom: ".js-canvasTargetDom",
    //         font:  ["50px", "'Homemade Apple'"],
    //         color: "blue",
    //         textString: "sellaraJA",
    //         customFont: { 
    //           name: "'Homemade Apple'", 
    //           url: "http://fonts.googleapis.com/css?family=Homemade+Apple"  
    //         }
    // };

    // const textSignature = new TextSignature(optionsParameter);

    const convertToFancyText = (input) => {
        if (input === '') return '';
        const charMap = {
            a: '𝓪', b: '𝓫', c: '𝓬', d: '𝓭', e: '𝓮',
            f: '𝓯', g: '𝓰', h: '𝓱', i: '𝓲', j: '𝓳',
            k: '𝓴', l: '𝓵', m: '𝓶', n: '𝓷', o: '𝓸',
            p: '𝓹', q: '𝓺', r: '𝓻', s: '𝓼', t: '𝓽',
            u: '𝓾', v: '𝓿', w: '𝔀', x: '𝔁', y: '𝔂',
            z: '𝔃'
        };
    
        return input
            .split('')
            .map(char => charMap[char.toLowerCase()] || char)
            .join('');
    };
    
    const renderTypedSignature = async (text1) => {
        const text = await convertToFancyText(text1); // Await the result
        const canvas = canvasRef.current;
    
        if (canvas) {
            const context = canvas.getContext("2d");
            const ratio = Math.max(window.devicePixelRatio || 1, 1);
    
            // Set up the canvas dimensions and scaling
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = 150 * ratio;
            context.scale(ratio, ratio);
    
            // Clear the canvas
            context.clearRect(0, 0, canvas.width, canvas.height);
    
            // Set text properties
            context.fillStyle = "gray";
            context.textAlign = "center";
            context.textBaseline = "middle";
            context.font = "40px 'Great Vibes', cursive";
    
            // Draw the text
            context.fillText(text, canvas.offsetWidth / 2, canvas.offsetHeight / 2);
    
            // Update the image data in `OngenrateSignedPdf`
            const base64Image = canvas.toDataURL().replace(/^data:image\/\w+;base64,/, '');
            updateImageData(base64Image);
        }
    };
    

    useEffect(() => {
        //if(!OpenSignaturePad)
        {
            setSavedSignature(false)
            setNameSignature('')
        }

    }, [OpenSignaturePad])
    useEffect(() => {
        if (currentMode == 'draw') {
            //console.log(currentMode)
            initializeSignaturePad()
        }
        else if (currentMode == 'type') {
            renderTypedSignature('Please type name')
        }
        //setNameSignature('')
        updateImageData(null)
        setImageData(null)


    }, [currentMode])

    const updateImageData = async (base64Image) => {

        if (tittle === 'Prepared By') {
            await OngenrateSignedPdf('preparedBySign', 'Image', base64Image)
        }
        else if (tittle === "Approver 1") {
            await OngenrateSignedPdf('approvedBySign', 'Image', base64Image)
        }
        else
            await OngenrateSignedPdf('approved2BySign', 'Image', base64Image)
    }
    // Initialize SignaturePad
    const initializeSignaturePad = () => {
        // setNameSignature('')
        const canvas = canvasRef.current;

        if (canvas) {
            // Set the canvas width and height dynamically
            const ratio = Math.max(window.devicePixelRatio || 1, 1);
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = 200 * ratio;
            canvas.getContext("2d").scale(1, 1);

            // Create a new SignaturePad instance
            signaturePadRef.current = new SignaturePadLibrary(canvas, {
                backgroundColor: "transparent",

                penColor: "gray",
            });

            // Add event listener for "endStroke" event when drawing stops
            signaturePadRef.current.addEventListener("endStroke", async () => {
                // Save the signature as a data URL
                // const signatureDataUrl = signaturePadRef.current.toDataURL();
                //setSignatureImage(signatureDataUrl);
                //console.log("Signature updated:", signatureDataUrl);
                if (signaturePadRef.current) {
                    setSavedSignature(true);


                    const base64Image = signaturePadRef.current.toDataURL().replace(/^data:image\/\w+;base64,/, ''); // Str
                    await updateImageData(base64Image)

                }


            });

        }
    };

    useEffect(() => {
        if (OpenSignaturePad) {
            // Delay initialization to ensure modal renders fully
            setTimeout(() => {
                initializeSignaturePad();
            }, 100);
        }

        // Cleanup SignaturePad on modal close
        return () => {
            if (signaturePadRef.current) {
                signaturePadRef.current.off();
                signaturePadRef.current = null;
            }
        };
    }, [OpenSignaturePad]);


    // Handle file upload
    const handleImageUpload = async (file) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            setFileName(file.name)
            setImageData(e.target.result);
            const base64Image = e.target.result.replace(/^data:image\/\w+;base64,/, '');
            await updateImageData(base64Image)
        };
        reader.readAsDataURL(file);
        return false;
    };

    // Handle Save
    const handleSave = async () => {
        // console.log('image', signaturePadRef.current.toDataURL())
        if (signaturePadRef.current) {
            setSavedSignature(signaturePadRef.current.toDataURL());
            const base64Image = signaturePadRef.current.toDataURL().replace(/^data:image\/\w+;base64,/, '');
            data = {
                signImage: base64Image,
                SignName: nameSignature
            }
            await OngenrateSignedPdf(tittle === 'Prepared By' ? 'preparedBySign' : 'approvedBySign', data)
            //setOpenSignaturePad(false);
        }
    };

    // Handle Undo
    const handleUndo = () => {
        if (signaturePadRef.current) {
            const data = signaturePadRef.current.toData();
            if (data.length) {
                redoStack.current.push(data.pop());
                signaturePadRef.current.fromData(data);
            }
        }
    };

    // Handle Redo
    const handleRedo = () => {
        if (signaturePadRef.current && redoStack.current.length) {
            const data = signaturePadRef.current.toData();
            data.push(redoStack.current.pop());
            signaturePadRef.current.fromData(data);
        }
    };

    // Handle Clear
    const handleClear = async () => {
        if (signaturePadRef.current) {
            signaturePadRef.current.clear();
        }

        if (currentMode == 'type') {
            renderTypedSignature('')
            setNameSignature('')
            handleSingaureName('')
        }
        setSavedSignature(false)
        setImageData(null)
        if (tittle === 'Prepared By') {
            await OngenrateSignedPdf('preparedBySign', 'Image', null)
        }
        else if (tittle === "Approver 1") {
            await OngenrateSignedPdf('approvedBySign', 'Image', null)
        }
        else
            await OngenrateSignedPdf('approved2BySign', 'Image', null)

    };
    const handleSingaureName = async (name) => {

        setNameSignature(name)

        if (tittle === 'Prepared By') {
            await OngenrateSignedPdf('preparedBySign', 'Name', name)
        }
        else if (tittle === "Approver 1") {
            await OngenrateSignedPdf('approvedBySign', 'Name', name)
        }
        else
            await OngenrateSignedPdf('approved2BySign', 'Name', name)
    };


    return (
        <div>

            <div id="signature-pad" style={{ border: "1px solid #ddd", borderRadius: '20px', padding: "5px", marginRight: '10px' }}>
                <Form.Item
                    required={savedSignature}
                    label={'Name'}
                    labelCol={{ span: 0 }} // Adjust label width
                    wrapperCol={{ span: 21 }} // Adjust input width
                    style={{ marginLeft: "10px", marginBottom: '10px' }} // Removes extra margin below the Form.Item
                >
                    <Input
                        onChange={(e) => {
                            if (e.target.value.length <= 30) {
                                handleSingaureName(e.target.value);
                                if (currentMode === "type")
                                    renderTypedSignature(e.target.value)// Generate dynamic signature preview
                            }
                        }}
                        value={nameSignature}
                        placeholder="Enter the name"
                        size="large"
                        maxLength={35} // Restricts user input to 30 characters
                        style={{
                            width: "100%", // Ensures input takes the remaining space
                        }}
                    />
                </Form.Item>
                {/* Mode Selector Buttons */}
                <div style={{ display: "flex", justifyContent: "center", marginBottom: "5px" }}>
                    <Button
                        type={currentMode === "draw" ? "primary" : "default"}
                        onClick={() => setCurrentMode("draw")}
                        icon={<FileImageOutlined />}
                        style={{ marginLeft: "0px", height: '40px' }}
                    >
                        Draw
                    </Button>
                    <Button
                        type={currentMode === "type" ? "primary" : "default"}
                        onClick={() => setCurrentMode("type")}
                        icon={<EditOutlined />}
                        style={{ marginLeft: "10px", height: '40px' }}
                    >
                        Type
                    </Button>

                    <Button
                        type={currentMode === "image" ? "primary" : "default"}
                        onClick={() => setCurrentMode("image")}
                        icon={<CloudUploadOutlined style={{ paddingBottom: '2px' }} />}
                        style={{ marginLeft: "10px", height: '40px' }}
                    >
                        Image
                    </Button>

                </div>

                {/* Signature Modes */}
                {currentMode === "draw" && (
                    <canvas
                        ref={canvasRef}
                        style={{ width: "100%", height: "150px", marginBottom: "10px" }}
                    ></canvas>
                )}
                {currentMode === "type" && (
                    <div style={{ textAlign: "center" }}>
                        {/* Input for typing signature */}
                        {/* <Input
                            value={nameSignature}
                            onChange={(e) => {
                                const value = e.target.value;
                                setNameSignature(value);
                                renderTypedSignature(value); // Generate dynamic signature preview
                            }}
                            placeholder="Type your signature"
                            size="large"
                            style={{ marginBottom: "10px" }}
                        /> */}

                        {/* Canvas for rendering typed signature */}
                        <canvas
                            ref={canvasRef}
                            style={{
                                //border: "1px solid #ddd",
                                width: "100%",
                                height: "150px",
                                display: "block"
                            }}
                        ></canvas>
                    </div>
                )}

                {currentMode === "image" && imageData && (
                    <div>
                        <img
                            src={imageData}
                            alt="Uploaded signature"
                            style={{
                                width: "100%",
                                maxHeight: "150px",
                                objectFit: "contain",
                                marginBottom: "10px",
                            }}
                        />
                        <div style={{ textAlign: "center" }}> {fileName} </div>
                    </div>
                )}

                {currentMode === "image" && !imageData &&

                    <div className='uploadFile_admin' >
                        <Upload maxCount={1} accept="image/*"
                            beforeUpload={handleImageUpload}
                            showUploadList={false}>
                            <Button style={{ width: "80%", height: "150px", marginBottom: "10px" }} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                        </Upload>
                    </div>
                }

                {/* { drawSignature && 

                    <div>
                        <canvas
                            ref={canvasRef}
                            style={{ width: "100%", height: "150px", marginBottom: "0px" }}
                        ></canvas>
                    </div>
                } */}
                <div className="mb-0 add-new-calibration Singanure-line" style={{ textAlign: "center", marginRight: '0%' }}>
                    ______________________________________________________________________
                </div>
                <Title className="mb-0 add-new-calibration" level={3} style={{ fontSize: '14px', textAlign: 'center' }}>
                    {tittle}
                </Title>
                <div style={{ marginTop: "0", marginLeft: '0', display: "flex", gap: '0px' }} className="signature-btn">
                    <Button type="primary" onClick={handleClear} disabled={(currentMode === "draw" && !savedSignature) || (currentMode === "image" && !imageData)} style={{ marginLeft: '85%', marginTop: '-24px', marginBottom: '3px' }}>
                        Clear
                    </Button>
                </div>


            </div>
            {/* {savedSignature && (
                <div className="saved-signature" style={{ marginTop: "20px" }}>
                    <h3>
                        Your Signature <EditOutlined />
                    </h3>
                    <img
                        className="signature-image"
                        alt="saved-signature"
                        src={savedSignature}
                        style={{ border: "1px solid #ddd", padding: "5px", maxWidth: "100%" }}
                    />
                </div>
            )} */}

        </div>
    );
};

export default SignaturePad;