/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { ReactGrid } from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';
import { Typography, Modal, notification, Button, Form, Select, Col, Row, Input, Spin, Table } from 'antd';
import BillingGridExpand from './BillingGridExpand';

const getValueOrDefault = (value, defaultValue) => (value !== null && value !== undefined) ? value : defaultValue;

const generateColumns = (data, selectedPipeline, visibleColumns = []) => {
    const columns = [
        { columnId: "dsrDate", width: 150, colspan: 2 },
        { columnId: "dayName", width: 100 },
        { columnId: "mobCount", width: 50, colspan: 2 },
        { columnId: "mobPersonnel", width: 250 },
        { columnId: "demobCount", width: 50, colspan: 2 },
        { columnId: "demobPersonnel", width: 250 },
    ];

    const pipeline = data.pipelineDetails?.find(p => p.pipelineName === selectedPipeline);

    if (pipeline) {
        pipeline.personnelData.forEach(role => {
            columns.push({ columnId: `${pipeline.pipelineName}-${role.roleName}Count`, width: 50, colspan: 2 });
            columns.push({ columnId: `${pipeline.pipelineName}-${role.roleName}Personnel`, width: 250 });
        });

        columns.push({ columnId: `${pipeline.pipelineName}-mobilizedCount`, width: 100 });
        columns.push({ columnId: `${pipeline.pipelineName}-demobilizedCount`, width: 100 });
        columns.push({ columnId: `${pipeline.pipelineName}-workingCount`, width: 100 });
        columns.push({ columnId: `${pipeline.pipelineName}-standByCount`, width: 100 });
        columns.push({ columnId: `${pipeline.pipelineName}-addColOne`, width: 100 });
        columns.push({ columnId: `${pipeline.pipelineName}-addColTwo`, width: 350 });
    }

    // return columns;
    const filteredColumns = columns.map(col => ({
        ...col,
        width: col.width || 100
    })).filter(col => visibleColumns.includes(col.columnId));
    return filteredColumns;
};

const generateHeaderRow = (columns, initialData, selectedPipeline) => {
    const cells = columns.map(column => {
        let text = column.columnId;
        const pipeline = initialData.pipelineDetails?.find(p => p.pipelineName === selectedPipeline);

        if (pipeline) {
            pipeline.personnelData.forEach(role => {
                const specificColumnId = `${pipeline.pipelineName}-${role.roleName}Count`;
                const personnelColumnId = `${pipeline.pipelineName}-${role.roleName}Personnel`;

                if (column.columnId === specificColumnId || column.columnId === personnelColumnId) {
                    text = `${role.roleName}`;
                }
            });
            initialData.equipmentDataDetails?.forEach(role => {
                const workingCountColumnId = `${pipeline.pipelineName}-workingCount`;
                const standByCountColumnId = `${pipeline.pipelineName}-standByCount`;
                const mobilizedCountColumnId = `${pipeline.pipelineName}-mobilizedCount`;
                const demobilizedCountColumnId = `${pipeline.pipelineName}-demobilizedCount`;
                const inTransitColumnId = `${pipeline.pipelineName}-addColOne`;
                const commentColumnId = `${pipeline.pipelineName}-addColTwo`;

                if (column.columnId === workingCountColumnId) {
                    text = `Working`;
                } else if (column.columnId === standByCountColumnId) {
                    text = `StandBy`;
                } else if (column.columnId === mobilizedCountColumnId) {
                    text = `Mobilized`;
                } else if (column.columnId === demobilizedCountColumnId) {
                    text = `Demobilized`;
                }
                else if (column.columnId === inTransitColumnId) {
                    text = `In Transit`;
                } else if (column.columnId === commentColumnId) {
                    text = `Comments`;
                }
            })
        }

        if (column.columnId === 'dsrDate') {
            text = ``;
        }
        if (column.columnId === 'dayName') {
            text = `Day`;
        }
        if (column.columnId === 'mobCount') {
            text = `Mob`;
        }
        if (column.columnId === 'demobCount') {
            text = `Demob`;
        }

        return {
            type: 'header',
            text: text,
            colspan: column.colspan || 1,
            style: { backgroundColor: 'transparent', fontSize: '14px', height: '40px', lineHeight: '40px' },
        };
    });

    return {
        rowId: 'header',
        cells: cells,
    };
};

const getCellStyle = (item) => ({
    border: {
        left: { color: "transparent", style: "", width: "1px" },
        right: { color: "transparent", style: "", width: "1px" },
        bottom: { color: "#afafaf", style: "", width: "1px" },
        top: { color: "transparent", style: "", width: "1px" },
    },
    background: 'transparent',
});

// Function to generate total row
const generateTotalRow = (data, columns, selectedPipeline) => {
    const totalRow = {
        rowId: 'total',
        cells: columns.map(column => {
            const columnId = column.columnId;

            // Define the default cell object
            let cell = { type: 'text', text: '', className: "total-billing-cell", nonEditable: true, style: getCellStyle() };

            // Calculate totals based on column ID
            if (columnId === 'dsrDate') {
                cell.text = 'Movement:-';
            } else if (columnId === 'dayName') {
                cell.text = '';
            } else if (columnId.endsWith('Count')) {
                let total = 0;

                // Sum up totals for mobCount and demobCount
                if (columnId === 'mobCount' || columnId === 'demobCount') {
                    data.mobDemobDetails.forEach(pipeline => {
                        if (pipeline.pipelineName === selectedPipeline) {
                            pipeline.personnelMobDemobDetails.forEach(item => {
                                total += Number(columnId === 'mobCount' ? getValueOrDefault(item.mobCount, 0) : getValueOrDefault(item.demobCount, 0))
                            })
                        }
                    });
                }

                // Sum up totals for personnel counts
                data.pipelineDetails.forEach(pipeline => {
                    if (pipeline.pipelineName === selectedPipeline) {
                        pipeline.personnelData.forEach(role => {
                            if (columnId.startsWith(`${pipeline.pipelineName}-${role.roleName}Count`)) {
                                role.data.forEach(item => {
                                    total += Number(getValueOrDefault(item.personnelCount, 0));
                                });
                            }
                        });
                    }
                });

                // Sum up totals for equipment counts
                data.equipmentDataDetails.forEach(equipment => {
                    if (equipment.pipelineName === selectedPipeline) {
                        equipment.equipmentData.forEach(equipmentDetails => {
                            if (columnId === `${equipment.pipelineName}-workingCount`) {
                                total += Number(getValueOrDefault(equipmentDetails.workingCount, 0));
                            } else if (columnId === `${equipment.pipelineName}-standByCount`) {
                                total += Number(getValueOrDefault(equipmentDetails.standByCount, 0));
                            } else if (columnId === `${equipment.pipelineName}-mobilizedCount`) {
                                total += Number(getValueOrDefault(equipmentDetails.mobilizedCount, 0));
                            } else if (columnId === `${equipment.pipelineName}-demobilizedCount`) {
                                total += Number(getValueOrDefault(equipmentDetails.demobilizedCount, 0));
                            }
                        });
                    }
                });

                cell.text = total.toFixed(2).toString();
            }

            return cell;
        })
    };
    return totalRow;
};


// Function to generate rows based on data
const generateRows = (data, selectedPipeline, visibleCols) => {
    const mobDemobSection = data.mobDemobDetails?.find(section => section.pipelineName === selectedPipeline);
    if (!mobDemobSection || !mobDemobSection.personnelMobDemobDetails) {
        return [];
    }

    const pipelineDetails = mobDemobSection.personnelMobDemobDetails;
    const rows = pipelineDetails.map((item, index) => {
        const row = {
            rowId: index,
            // height: 30,
            cells: [
                { type: "text", text: getValueOrDefault(item.dsrDate, ''), nonEditable: true, style: getCellStyle(item) },
                { type: "text", text: getValueOrDefault(item.dayName, ''), nonEditable: true, style: getCellStyle(item) },
                { type: "text", text: getValueOrDefault(item.mobCount, 0).toString(), style: getCellStyle(item) },
                { type: "text", text: getValueOrDefault(item.mobPersonnel, ''), style: getCellStyle(item) },
                { type: "text", text: getValueOrDefault(item.demobCount, 0).toString(), style: getCellStyle(item) },
                { type: "text", text: getValueOrDefault(item.demobPersonnel, ''), style: getCellStyle(item) },
            ]
        };

        const pipeline = data.pipelineDetails.find(p => p.pipelineName === selectedPipeline);
        if (pipeline) {
            pipeline.personnelData.forEach(role => {
                const roleData = role.data.find(d => d.dsrDate === item.dsrDate) || { personnelCount: 0, personnelName: "" };
                row.cells.push({ type: "text", text: getValueOrDefault(roleData.personnelCount, 0).toString(), style: getCellStyle(item) });
                row.cells.push({ type: "text", text: getValueOrDefault(roleData.personnelName, ''), style: getCellStyle(item) });
            });

            const equipmentData = data.equipmentDataDetails.find(e => e.pipelineName === pipeline.pipelineName)?.equipmentData || [];
            const equipmentDetails = equipmentData.find(e => e.dsrDate === item.dsrDate) || { workingCount: 0, standByCount: 0, mobilizedCount: 0, demobilizedCount: 0 };
            if (visibleCols.includes(`${selectedPipeline}-mobilizedCount`)) {
                row.cells.push({ type: "text", text: getValueOrDefault(equipmentDetails.mobilizedCount, 0).toString(), style: getCellStyle(item) });
            }
            if (visibleCols.includes(`${selectedPipeline}-demobilizedCount`)) {
                row.cells.push({ type: "text", text: getValueOrDefault(equipmentDetails.demobilizedCount, 0).toString(), style: getCellStyle(item) });
            }
            if (visibleCols.includes(`${selectedPipeline}-workingCount`)) {
                row.cells.push({ type: "text", text: getValueOrDefault(equipmentDetails.workingCount, 0).toString(), style: getCellStyle(item) });
            }
            if (visibleCols.includes(`${selectedPipeline}-standByCount`)) {
                row.cells.push({ type: "text", text: getValueOrDefault(equipmentDetails.standByCount, 0).toString(), style: getCellStyle(item) });
            }
            if (visibleCols.includes(`${selectedPipeline}-addColOne`)) {
                row.cells.push({ type: "text", text: getValueOrDefault(equipmentDetails.addColOne, ''), style: getCellStyle(item) });
            }
            if (visibleCols.includes(`${selectedPipeline}-addColTwo`)) {
                row.cells.push({ type: "text", text: getValueOrDefault(equipmentDetails.addColTwo, ''), style: getCellStyle(item) });
            }
        }

        return row;
    });

    const columns = generateColumns(data, selectedPipeline, visibleCols);
    const totalRow = generateTotalRow(data, columns, selectedPipeline);
    rows.push(totalRow);

    return rows;
};


const applyChanges = (changes, data, selectedPipeline) => {
    const updatedData = { ...data };

    changes.forEach(({ rowId, columnId, newCell }) => {
        // Find the correct pipeline and personnelMobDemobDetails based on selectedPipeline and rowId
        if (columnId === 'dsrDate' || columnId === 'dayName') return;
        let row;
        updatedData.mobDemobDetails.forEach(pipeline => {
            if (pipeline.pipelineName === selectedPipeline) {
                row = pipeline.personnelMobDemobDetails[rowId];

                // Update mobPersonnel and demobPersonnel in personnelMobDemobDetails
                if (columnId === 'mobPersonnel') {
                    row.mobPersonnel = newCell.text;
                }

                if (columnId === 'demobPersonnel') {
                    row.demobPersonnel = newCell.text;
                }

                // Update mobCount and demobCount in personnelMobDemobDetails
                if (columnId === 'mobCount') {
                    row.mobCount = parseInt(newCell.text, 10) || 0;
                }

                if (columnId === 'demobCount') {
                    row.demobCount = parseInt(newCell.text, 10) || 0;
                }

            }
        });

        // Update personnelName and personnelCount in pipelineDetails
        updatedData.pipelineDetails.forEach(pipeline => {
            if (pipeline.pipelineName === selectedPipeline) {
                pipeline.personnelData.forEach(role => {
                    if (columnId === `${pipeline.pipelineName}-${role.roleName}Personnel`) {
                        const roleData = role.data.find(d => d.dsrDate === row.dsrDate);
                        if (roleData) {
                            roleData.personnelName = newCell.text;
                        } else {
                            role.data.push({
                                dsrDate: row.dsrDate,
                                personnelCount: 0,
                                personnelName: newCell.text || ''
                            });
                        }
                    }

                    if (columnId === `${pipeline.pipelineName}-${role.roleName}Count`) {
                        const roleData = role.data.find(d => d.dsrDate === row.dsrDate);
                        if (roleData) {
                            roleData.personnelCount = parseInt(newCell.text, 10) || 0;
                        } else {
                            role.data.push({
                                dsrDate: row.dsrDate,
                                personnelCount: parseInt(newCell.text, 10) || 0,
                                personnelName: ''
                            });
                        }
                    }
                });
            }
        });

        // Update workingCount and standByCount in equipmentData
        if (!updatedData.equipmentDataDetails) {
            updatedData.equipmentDataDetails = [];
        }

        updatedData.equipmentDataDetails.forEach(equipment => {
            if (equipment.pipelineName === selectedPipeline) {
                const equipmentData = equipment.equipmentData.find(e => e.dsrDate === row.dsrDate);
                if (!equipmentData) {
                    // If equipmentData for the dsrDate doesn't exist, create a new entry
                    equipment.equipmentData.push({
                        dsrDate: row.dsrDate,
                        workingCount: 0,
                        standByCount: 0,
                        mobilizedCount: 0,
                        demobilizedCount: 0,
                        addColOne: '',
                        addColTwo: ''
                    });
                }

                // Find or create equipmentData object corresponding to row.dsrDate
                const foundEquipmentData = equipment.equipmentData.find(e => e.dsrDate === row.dsrDate);

                // Update the appropriate count based on columnId suffix
                if (columnId.endsWith('-workingCount')) {
                    equipmentData.workingCount = parseInt(newCell.text, 10) || 0;
                }

                if (columnId.endsWith('-standByCount')) {
                    equipmentData.standByCount = parseInt(newCell.text, 10) || 0;
                }

                if (columnId.endsWith('-mobilizedCount')) {
                    equipmentData.mobilizedCount = parseInt(newCell.text, 10) || 0;
                }

                if (columnId.endsWith('-demobilizedCount')) {
                    equipmentData.demobilizedCount = parseInt(newCell.text, 10) || 0;
                }

                if (columnId.endsWith('-addColOne')) {
                    equipmentData.addColOne = newCell.text || '';
                }

                if (columnId.endsWith('-addColTwo')) {
                    equipmentData.addColTwo = newCell.text || '';
                }
            }
        });
    });

    return updatedData;
};


//function to finalize the visible columns in billings table
function filterArray(a, b, c) {
    c = c.map(x => x.columnId)
    return b.filter(item => {
        if (!c.includes(item)) {
            return true;
        }
        return a.includes(item);
    });
}



const MonthlyRecordGrid = (props) => {
    const { monthName, year, billingTitle, setBillingTitle, billingModalVisible, setBillingModalVisible, initialData, data, setData, setBillingColumnList, visibleColumns, allBillingColumns, billingColumnList, setFilteredKeys,
        isBillingDataLoading, isInitialBillingDataLoading, selectedPipeline, setSelectedPipeline, pipelines, updateBillingData, billingPipelines
    } = props;

    // initialData.comments = '';
    const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth();

    const getDayOfWeek = (dateString) => {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const date = new Date(dateString);
        return daysOfWeek[date.getDay()];
    };

    //grouping pipeline details to update standby and working
    const groupedData = initialData.pipelineDetails?.reduce((acc, pipeline) => {
        pipeline.personnelData.forEach(role => {
            role.data.forEach(item => {
                const key = `${pipeline.pipelineName}-${item.dsrDate}`;
                acc[key] = (acc[key] || 0) + item.personnelCount;
            });
        });
        return acc;
    }, {});

    const addMissingDates = (initialData, month, year) => {
        const numOfDays = new Date(year, month + 1, 0).getDate();
        const formattedMonth = String(month + 1).padStart(2, '0');
        //mobDemobDetails
        initialData.mobDemobDetails?.forEach(section => {
            const pipelineName = section.pipelineName;
            const personnelDetails = section.personnelMobDemobDetails;

            const existingDates = new Set(personnelDetails.map(entry => entry.dsrDate));

            for (let day = 1; day <= numOfDays; day++) {
                const dateString = `${year}-${formattedMonth}-${String(day).padStart(2, '0')}`;
                const dayOfWeek = getDayOfWeek(dateString);

                if (!existingDates.has(dateString)) {
                    personnelDetails.push({
                        "dayName": dayOfWeek,
                        "dsrDate": dateString,
                        "mobCount": 0,
                        "demobCount": 0,
                        "mobPersonnel": null,
                        "demobPersonnel": null
                    });
                }
            }

            personnelDetails.sort((a, b) => new Date(a.dsrDate) - new Date(b.dsrDate));
        });
        //pipelineDetails
        initialData.pipelineDetails?.forEach((pipeLine, index) => {
            if (!Array.isArray(pipeLine.personnelData)) {
                console.error(`personnelData at index ${index} is not an array:`, pipeLine.personnelData);
                return;
            }

            pipeLine.personnelData.forEach(role => {
                const personnelDetails = role.data;

                const existingDates = new Set(personnelDetails.map(entry => entry.dsrDate));

                for (let day = 1; day <= numOfDays; day++) {
                    const dateString = `${year}-${formattedMonth}-${String(day).padStart(2, '0')}`;
                    const dayOfWeek = getDayOfWeek(dateString);

                    if (!existingDates.has(dateString)) {
                        personnelDetails.push({
                            "dsrDate": dateString,
                            "personnelName": "",
                            "personnelCount": 0
                        });
                    }
                }
            });
        });

        //equipmentDetails
        if (!initialData?.equipmentDataDetails?.length) {
            initialData.equipmentDataDetails = [{
                pipelineName: selectedPipeline,
                equipmentData: []
            }]
        }
        initialData.equipmentDataDetails?.forEach(section => {
            const pipelineName = section.pipelineName;
            const personnelDetails = section.equipmentData;

            const existingDates = new Set(personnelDetails.map(entry => entry.dsrDate));

            for (let day = 1; day <= numOfDays; day++) {
                const dateString = `${year}-${formattedMonth}-${String(day).padStart(2, '0')}`;
                const dayOfWeek = getDayOfWeek(dateString);

                if (!existingDates.has(dateString)) {
                    personnelDetails.push({
                        "dsrDate": dateString,
                        "standByCount": 0,
                        "workingCount": 0,
                        "mobilizedCount": 0,
                        "demobilizedCount": 0,
                        "addColOne": '',
                        "addColTwo": ''
                    });
                }
            }

            // Update workingCount and standByCount based on groupedData
            if (groupedData) {
                personnelDetails.forEach(detail => {
                    if (detail.dsrDate) {
                        const key = `${pipelineName}-${detail.dsrDate}`;
                        const value = groupedData[key] || 0;

                        if (value > 0 && detail.jointsCount > 0) {
                            detail.workingCount = 1;
                            detail.standByCount = 0;
                        } else if (value > 0 && detail.jointsCount == 0) {
                            detail.workingCount = 0;
                            detail.standByCount = 1;
                        } else if (value == 0 && detail.jointsCount > 0) {
                            detail.workingCount = 0;
                            detail.standByCount = 1;
                        }
                    } else {
                        console.warn('dsrDate is missing for a personnel detail:', detail);
                    }
                });
            }

        });

        return initialData;
    };
    const updatedData = addMissingDates(initialData, monthIndex, year);

    // const [data, setData] = useState(initialData);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [pipeLine, setPipeLine] = useState([]);
    const [padding, setPadding] = useState(['123px', '224px']);
    const [paddingPipeline, setPaddingPipeline] = useState('');
    const [updatedBillingData, setUpdatedBillingData] = useState();

    const pipelineDetails = initialData.pipelineDetails ? initialData.pipelineDetails.filter(section => section.pipelineName == selectedPipeline) : [];

    useEffect(() => {
        setUpdatedBillingData(initialData)
    }, [initialData])

    useEffect(() => {
        const visibleCols = filterArray(visibleColumns, allBillingColumns, billingColumnList)
        const filterKey = billingColumnList.filter(item => !visibleColumns.includes(item.columnId));
        const cols = generateColumns(initialData, selectedPipeline, visibleCols);
        const header = generateHeaderRow(cols, initialData, selectedPipeline);
        const dataRows = generateRows(initialData, selectedPipeline, visibleCols);
        const updatedPipeline = ['Date', 'Personnel Mob I Demob', selectedPipeline];
        const updatedPadding = [...pipelineDetails.map(item => {
            let totalPx = 50 * (visibleColumns.length);
            if (visibleColumns.some(column => column.endsWith('-addColTwo'))) {
                totalPx += 125;
            }
            if (item.personnelData.length) {
                totalPx += (item.personnelData.length * 150)
            }
            if (item.pipelineName) {
                totalPx -= (item.pipelineName.length * 3.5);
            }
            return `${totalPx}px`
        })];
        setPipeLine(updatedPipeline)
        // setPadding(updatedPadding)
        setPaddingPipeline(updatedPadding[0])
        setColumns(cols);
        setRows([header, ...dataRows]);
        setFilteredKeys(filterKey.map(x => x.columnId))
    }, [data, selectedPipeline, visibleColumns, updatedData]);

    const handleCancel = () => {
        setBillingModalVisible(false);
    };

    const handleChanges = (changes) => {
        const updatedData = applyChanges(changes, data, selectedPipeline);
        setData(updatedData);
        const visibleCols = filterArray(visibleColumns, allBillingColumns, billingColumnList)
        const cols = generateColumns(updatedData, selectedPipeline, visibleCols);
        const header = generateHeaderRow(cols, initialData.pipelineDetails, selectedPipeline);
        const dataRows = generateRows(updatedData, selectedPipeline, visibleCols);
        setColumns(cols);
        setRows([header, ...dataRows]);
    };
    const margins = ['0px', '0px', '0px', '0px', '0px', '0px', '0px'];
    const paddingLeft = ['90px', '196px', '0px', '0px', '0px']
    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden billing-inventory">
            <Spin spinning={isBillingDataLoading || isInitialBillingDataLoading}>
                <div className='dashboard-grid mt-1'>
                    {(initialData.mobDemobDetails?.length && billingPipelines.includes(selectedPipeline)) ?
                        <>
                            <div style={{ display: 'flex' }}>
                                {pipeLine.map((name, index) => (
                                    <h6
                                        className='grid-header'
                                        style={{
                                            marginLeft: margins[index],
                                            paddingRight: (name == 'Date' || name == 'Personnel Mob I Demob') ? padding[index] : paddingPipeline,
                                            paddingLeft: (name == 'Date' || name == 'Personnel Mob I Demob') ? paddingLeft[index] : paddingPipeline,
                                        }} key={index} >
                                        {name}
                                    </h6>
                                ))}
                            </div>
                            <ReactGrid
                                rows={rows}
                                columns={columns}
                                onCellsChanged={handleChanges}
                            />
                        </> :
                        <Table
                            dataSource={null}
                        />
                    }
                </div>
            </Spin>
            {billingModalVisible &&

                <BillingGridExpand
                    billingTitle={billingTitle}
                    billingModalVisible={billingModalVisible}
                    handleCancel={handleCancel}
                    pipeLine={pipeLine}
                    margins={margins}
                    padding={padding}
                    paddingLeft={paddingLeft}
                paddingPipeline={paddingPipeline}
                    rows={rows}
                    columns={columns}
                    handleChanges={handleChanges}
                    selectedPipeline={selectedPipeline}
                    setSelectedPipeline={setSelectedPipeline}
                    pipelines={pipelines}
                    updateBillingData={updateBillingData}
                    isBillingDataLoading={isBillingDataLoading}
                    isInitialBillingDataLoading={isInitialBillingDataLoading}
                initialData={initialData}
                billingPipelines={billingPipelines}
                />
            }
        </div>
    );
};

export default MonthlyRecordGrid;