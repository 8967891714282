import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import axiosConfig from '../../services/axiosConfig';
import {
    fetchInvoiceDashBoardListSuccess, fetchInvoiceDashBoardListFailure, fetchBillingDashBoardListSuccess, fetchBillingDashBoardListFailure, fetchInitialBillingDashBoardListSuccess, fetchInitialBillingDashBoardListFailure,
    fetchBillingDashBoardPdfUrlSuccess, fetchBillingDashBoardPdfUrlFailure, fetchInvoiceMonthListSuccess, fetchInvoiceMonthListFailure, fetchBillingMonthListSuccess, fetchBillingMonthListFailure, fetchRatesListSuccess, fetchRatesListFailure, addRateSuccess, addRateFailure, fetchRechargeListSuccess, fetchRechargeListFailure, addRechargeFailure, fetchRechargeChargeListSuccess, fetchRechargeChargeListFailure, fetchRechargeMonthListSuccess, fetchRechargeMonthListFailure
} from './action';
import {
    FETCH_INVOICE_LIST, UPDATE_DASHBOARD_INVOICE, FETCH_BILLING_LIST, FETCH_BILLING_INITIAL_LIST, UPDATE_DASHBOARD_BILLING, FETCH_BILLING_PDF_URL, FETCH_INVOICE_PDF_URL, SEND_EMAIL_FOR_SIGNATURE_INVOICE, FETCH_INVOICE_MONTH_LIST, FETCH_BILLING_MONTH_LIST,
    FETCH_RATES_LIST, ADD_RATE, UPDATE_RATES_LIST, DELETE_RATE, UPLOAD_BULK_RATES_LIST, FETCH_RECHARGE_LIST, ADD_RECHARGE, UPDATE_RECHARGE_LIST, DELETE_RECHARGE, FETCH_CHARGE_LIST, FETCH_RECHARGE_MONTH_LIST, GENERATE_RECHARGE_EXCEL, RESET_DASHBOARD_BILLING
} from './types';
import get from 'lodash/get';
import { notification } from 'antd';
import axios from 'axios';


/** Get all invoices list Data */
function* fetchInvoiceDashBoardList({ projectId, invoiceMonthAndYear }) {
    try {
        let url = `/project-invoice?projectId=${projectId}&invoiceMonthAndYear=${invoiceMonthAndYear}`;
        const res = yield axiosConfig.get(url)
        let response = get(res, ['data', 'data'], {});
        if (!response) {
            response = [{}]
        }
        if (res.status === 200) {
            yield put(fetchInvoiceDashBoardListSuccess({ response }));
        } else {
            yield put(fetchInvoiceDashBoardListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchInvoiceDashBoardListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching invoices month list.',
        });
    }
}

/** Get all invoices Months list */
function* fetchInvoiceMonthList({ projectId }) {
    try {
        let url = `/project-invoice/persisted-months?projectId=${projectId}`;
        const res = yield axiosConfig.get(url)
        let response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchInvoiceMonthListSuccess({ response }));
        } else {
            yield put(fetchInvoiceMonthListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchInvoiceMonthListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching invoices list.',
        });
    }
}

/** Get all invoice pdf url */
function* fetchInvoiceDashBoardPdfUrl({ projectId, invoiceMonthAndYear }) {
    try {
        let url = `/project-invoice-report?projectId=${projectId}&invoiceMonthAndYear=${invoiceMonthAndYear}`;
        const res = yield axiosConfig.get(url)
        // let response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield fetchInvoiceDashBoardList({ projectId, invoiceMonthAndYear })
            notification.success({
                message: 'Pdf generated Successfully.',
            });
        } else {
            yield fetchInvoiceDashBoardList({ projectId, invoiceMonthAndYear })
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield fetchInvoiceDashBoardList({ projectId, invoiceMonthAndYear })
        notification.destroy();
        notification.error({
            message: 'A problem occurred while generating pdf.',
            description: `${error.error}`,
        });
    }
}

/** Get all billing list Data */
function* fetchBillingDashBoardList({ projectId, billingMonthAndYear }) {
    try {
        let url = `/project-billing?projectId=${projectId}&billingMonthAndYear=${billingMonthAndYear}`;
        const res = yield axiosConfig.get(url)
        let response = get(res, ['data', 'data'], {});
        if (!response) {
            response = [{}]
        }
        if (res.status === 200) {
            yield put(fetchBillingDashBoardListSuccess({ response }));
        } else {
            yield put(fetchBillingDashBoardListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchBillingDashBoardListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching billing list.',
        });
    }
}

/** Get all invoices Months list */
function* fetchBillingMonthList({ projectId }) {
    try {
        let url = `/project-billing/persisted-months?projectId=${projectId}`;
        const res = yield axiosConfig.get(url)
        let response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchBillingMonthListSuccess({ response }));
        } else {
            yield put(fetchBillingMonthListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchBillingMonthListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching billing months list.',
        });
    }
}

/** Get all billing pdf url */
function* fetchBillingDashBoardPdfUrl({ projectId, billingMonthAndYear, data }) {
    try {
        let url = `/monthly-record-of-work-report?projectId=${projectId}&monthAndYear=${billingMonthAndYear}`;
        const res = yield axiosConfig.put(url, data)
        // let response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield fetchBillingDashBoardList({ projectId, billingMonthAndYear })
            notification.success({
                message: 'Pdf generated Successfully.',
            });
        } else {
            yield fetchBillingDashBoardList({ projectId, billingMonthAndYear })
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield fetchBillingDashBoardList({ projectId, billingMonthAndYear })
        notification.destroy();
        notification.error({
            message: 'A problem occurred while generating pdf.',
        });
    }
}

/** Get all Initial billing list Data */
function* fetchInitialBillingDashBoardList({ projectId, monthAndYear }) {
    try {
        let url = `/dsr-project-billing?projectId=${projectId}&monthAndYear=${monthAndYear}`;
        const res = yield axiosConfig.get(url)
        let response = get(res, ['data', 'data'], {});
        if (!response) {
            response = [{}]
        }
        if (res.status === 200) {
            yield put(fetchInitialBillingDashBoardListSuccess({ response }));
        } else {
            yield put(fetchInitialBillingDashBoardListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchInitialBillingDashBoardListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching billing list.',
        });
    }
}

/** Get all recharge list of data */
function* fetchRechargeList({ projectId, monthAndYear }) {
    try {
        const res = yield axiosConfig.get(`/recharge?projectId=${projectId}&monthAndYear=${monthAndYear}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchRechargeListSuccess({ response }));
        } else {
            yield put(fetchRechargeListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }

    }
    catch (error) {
        yield put(fetchRechargeListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching recharge list.',
            description: `${error}`,
        });
    }
}

/** Get all recharge charge list of data */
function* fetchRechargeChargeList({ projectId, activityType }) {
    try {
        const res = yield axiosConfig.get(`/rate?projectId=${projectId}&activityType=${activityType}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchRechargeChargeListSuccess({ response }));
        } else {
            yield put(fetchRechargeChargeListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }

    }
    catch (error) {
        yield put(fetchRechargeChargeListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching recharge list.',
            description: `${error}`,
        });
    }
}

/** Get all recharge Months and Year list */
function* fetchRechargeMonthList({ projectId }) {
    try {
        let url = `/recharge/persisted-months?projectId=${projectId}`;
        const res = yield axiosConfig.get(url)
        let response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchRechargeMonthListSuccess({ response }));
        } else {
            yield put(fetchRechargeMonthListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchRechargeMonthListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching recharge months and years list.',
        });
    }
}

/** Get all rates list of data */
function* fetchRatesList({ projectId }) {
    try {
        const res = yield axiosConfig.get(`/rate?projectId=${projectId}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchRatesListSuccess({ response }));
        } else {
            yield put(fetchRatesListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }

    }
    catch (error) {
        yield put(fetchRatesListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching rate list.',
            description: `${error.error}`,
        });
    }
}

/** Function to send email for signature Dashboard Invoice */
function* sendEmailForSignatureInvoice(data) {
    const rawData = {
        "projectInvoiceId": data.projectInvoiceId,
        "reportVersion": data.reportVersion,
        "signerEmail": data.signerEmail,
        "signerName": data.signerName,
        "requestedByEmail": data.requestedByEmail,
        "requestedByName": data.requestedByName,
    }
    try {
        const res = yield axiosConfig.put(`/project-invoice-report/esign`, rawData);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchInvoiceDashBoardList({ projectId: data.projectId, invoiceMonthAndYear: data.invoiceMonthAndYear })
            notification.success({
                message: 'Signature requested successfully.',
            });
        } else {
            yield fetchInvoiceDashBoardList({ projectId: data.projectId, invoiceMonthAndYear: data.invoiceMonthAndYear })
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        yield fetchInvoiceDashBoardList({ projectId: data.projectId, invoiceMonthAndYear: data.invoiceMonthAndYear })
        notification.destroy();
        notification.error({
            message: 'Signature request Failed.',
            // description: `${error.error}`,
        });
    }
}

//Add a new Recharge 
function* addRecharge({ data, monthAndYear }) {
    try {
        const res = yield axiosConfig.post(`/recharge`, data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchRechargeList({ projectId: data.projectId, monthAndYear: monthAndYear });
            notification.success({
                message: `Row added successfully.`,
            });
        } else {
            yield fetchRechargeList({ projectId: data.projectId, monthAndYear: monthAndYear });
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(addRechargeFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occurred while adding the row.',
            description: `${error.error}`,
        });
    }
}

//Add a new Rate
function* addRate(payload) {
    try {
        const res = yield axiosConfig.post(`/rate`, payload);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchRatesList({ projectId: payload.projectId });
            notification.success({
                message: `Row added successfully.`,
            });
        } else {
            yield fetchRatesList({ projectId: payload.projectId });
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(addRateFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occurred while adding the row.',
            description: `${error.error}`,
        });
    }
}

//generate recharge excel url
function* generateExcelForRecharge(payload) {
    try {
        const res = yield axiosConfig.put(`/recharge-report`, payload);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchRechargeList({ projectId: payload.projectId, monthAndYear: payload.monthAndYear });
            notification.success({
                message: 'Report generated successfully.',
            });
        } else {
            yield fetchRechargeList({ projectId: payload.projectId, monthAndYear: payload.monthAndYear });
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        yield fetchRechargeList({ projectId: payload.projectId, monthAndYear: payload.monthAndYear });
        notification.destroy();
        notification.error({
            message: 'Report generation failed.',
            description: `${error.error}`,
        });
    }
}

//update recharge information
function* updateRecharge({ data, id, monthAndYear }) {
    try {
        const res = yield axiosConfig.put(`/recharge/${id}`, data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchRechargeList({ projectId: data.recharge.projectId, monthAndYear: monthAndYear });
            notification.success({
                message: 'Row updated successfully.',
            });
        } else {
            yield fetchRechargeList({ projectId: data.recharge.projectId, monthAndYear: monthAndYear });
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        yield fetchRechargeList({ projectId: data.recharge.projectId, monthAndYear: monthAndYear });
        notification.destroy();
        notification.error({
            message: 'Row data updates failed.',
            description: `${error.error}`,
        });
    }
}

//update Rate information
function* updateRate({ data, id }) {
    try {
        const res = yield axiosConfig.put(`/rate/${id}`, data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchRatesList({ projectId: data.rate.projectId });
            notification.success({
                message: 'Row updated successfully.',
            });
        } else {
            yield fetchRatesList({ projectId: data.rate.projectId });
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        yield fetchRatesList({ projectId: data.rate.projectId });
        notification.destroy();
        notification.error({
            message: 'Row data updates failed.',
            description: `${error.error}`,
        });
    }
}

//upload bulk rate list information
function* uploadBulkRatesList({ data, projectId }) {
    try {
        const res = yield axiosConfig.put(`/rate`, data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchRatesList({ projectId });
            notification.success({
                message: 'Rate list uploaded successfully.',
            });
        } else {
            yield fetchRatesList({ projectId });
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        yield fetchRatesList({ projectId });
        notification.destroy();
        notification.error({
            message: 'Rate data upload failed.',
            description: `${error.error}`,
        });
    }
}
/** Function to update Dashboard Invoice data list */
function* updateDashboardInvoice(data) {
    try {
        const res = yield axiosConfig.put(`/project-invoice`, data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchInvoiceDashBoardList({ projectId: data.projectId, invoiceMonthAndYear: data.invoiceMonthAndYear })
            notification.success({
                message: 'Rows Updated Successfully.',
            });
        } else {
            yield fetchInvoiceDashBoardList({ projectId: data.projectId, invoiceMonthAndYear: data.invoiceMonthAndYear })
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        yield fetchInvoiceDashBoardList({ projectId: data.projectId, invoiceMonthAndYear: data.invoiceMonthAndYear })
        notification.destroy();
        notification.error({
            message: 'Rows data Updation Failed.',
            // description: `${error.error}`,
        });
    }
}

/** Function to update Dashboard Billing data list */
function* updateDashboardBilling(data) {
    try {
        const res = yield axiosConfig.put(`/project-billing`, data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchBillingDashBoardList({ projectId: data.projectId, billingMonthAndYear: data.billingMonthAndYear })
            notification.success({
                message: 'Rows Updated Successfully.',
            });
        } else {
            yield fetchBillingDashBoardList({ projectId: data.projectId, billingMonthAndYear: data.billingMonthAndYear })
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        yield fetchBillingDashBoardList({ projectId: data.projectId, billingMonthAndYear: data.billingMonthAndYear })
        notification.destroy();
        notification.error({
            message: 'Rows data Updation Failed.',
            // description: `${error.error}`,
        });
    }
}

/** Function to reset Dashboard Billing data list */
function* resetDashboardBilling(data) {
    try {
        const res = yield axiosConfig.post(`/project-billing/reset`, data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchBillingDashBoardList({ projectId: data.projectId, billingMonthAndYear: data.billingMonthAndYear })
            notification.success({
                message: 'Data Reset Successfully.',
            });
        } else {
            yield fetchBillingDashBoardList({ projectId: data.projectId, billingMonthAndYear: data.billingMonthAndYear })
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        yield fetchBillingDashBoardList({ projectId: data.projectId, billingMonthAndYear: data.billingMonthAndYear })
        notification.destroy();
        notification.error({
            message: 'Data Reset Failed.',
            // description: `${error.error}`,
        });
    }
}

//Delete recharge 
function* deleteRecharge({ id, projectId, monthAndYear }) {
    try {
        const res = yield axiosConfig.delete(`/recharge/${id}`);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchRechargeList({ projectId: projectId, monthAndYear: monthAndYear });
            notification.success({
                message: 'Row deleted successfully.',
            });
        } else {
            yield fetchRechargeList({ projectId: projectId, monthAndYear: monthAndYear });
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        notification.destroy();
        yield fetchRechargeList({ projectId: projectId, monthAndYear: monthAndYear });
        notification.error({
            message: 'Data deletion failed.',
            description: `${error.error}`,
        });
    }
}

//Delete rate
function* deleteRate({ id, projectId }) {
    try {
        const res = yield axiosConfig.delete(`/rate/${id}`);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchRatesList({ projectId: projectId });
            notification.success({
                message: 'Row deleted successfully.',
            });
        } else {
            yield fetchRatesList({ projectId: projectId });
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        notification.destroy();
        yield fetchRatesList({ projectId: projectId });
        notification.error({
            message: 'Data deletion failed.',
            description: `${error.error}`,
        });
    }
}




function* BillingDashBoardWatcher() {
    yield takeLatest(FETCH_INVOICE_LIST, fetchInvoiceDashBoardList);
    yield takeLatest(FETCH_INVOICE_MONTH_LIST, fetchInvoiceMonthList);
    yield takeLatest(FETCH_INVOICE_PDF_URL, fetchInvoiceDashBoardPdfUrl);
    yield takeLatest(FETCH_RECHARGE_LIST, fetchRechargeList);
    yield takeLatest(FETCH_CHARGE_LIST, fetchRechargeChargeList);
    yield takeLatest(FETCH_RATES_LIST, fetchRatesList);
    yield takeLatest(FETCH_RECHARGE_MONTH_LIST, fetchRechargeMonthList);
    yield takeLatest(SEND_EMAIL_FOR_SIGNATURE_INVOICE, sendEmailForSignatureInvoice);
    yield takeLatest(FETCH_BILLING_LIST, fetchBillingDashBoardList);
    yield takeLatest(FETCH_BILLING_MONTH_LIST, fetchBillingMonthList);
    yield takeLatest(FETCH_BILLING_PDF_URL, fetchBillingDashBoardPdfUrl);
    yield takeLatest(FETCH_BILLING_INITIAL_LIST, fetchInitialBillingDashBoardList);
    yield takeLatest(RESET_DASHBOARD_BILLING, resetDashboardBilling);
    yield takeLatest(ADD_RECHARGE, addRecharge);
    yield takeLatest(ADD_RATE, addRate);
    yield takeLatest(UPDATE_DASHBOARD_INVOICE, updateDashboardInvoice);
    yield takeLatest(UPDATE_DASHBOARD_BILLING, updateDashboardBilling);
    yield takeLatest(GENERATE_RECHARGE_EXCEL, generateExcelForRecharge);
    yield takeLatest(UPDATE_RECHARGE_LIST, updateRecharge);
    yield takeLatest(UPDATE_RATES_LIST, updateRate);
    yield takeLatest(UPLOAD_BULK_RATES_LIST, uploadBulkRatesList);
    yield takeLatest(DELETE_RECHARGE, deleteRecharge);
    yield takeLatest(DELETE_RATE, deleteRate);
}

function* billingDashBoardSagas() {
    yield all([
        call(BillingDashBoardWatcher),
    ]);
}

export default billingDashBoardSagas;